<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="onDismiss"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label @click="onDismiss" class="head-title ml-2">{{ $t('invoice_cn.preview') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="isBatchDoc" class="mt-5 mx-4 text-center">
        <ion-text class="fw-600 fs-5">{{ $t('no_preview_docs') }}</ion-text>
      </div>
      <div v-else>
        <div v-for="(item, idx) in pdfUrls" :key="idx">
          <vue-pdf-embed ref="pdfRef" @rendered="onRender" :source="item" class="pdf"></vue-pdf-embed>
        </div>
        <ion-loading
          :is-open="isRenderingDoc"
          @didDismiss="setOpenDownload(false)"
          cssClass="custom-loading"
          message=""
          spinner="crescent"
        >
        </ion-loading>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar class="mx-0">
        <ion-button @click="openSocialSharing" class="mx-2" expand="block">
          <ion-icon slot="start" :icon="shareOutline"></ion-icon>
          {{ $t('share') }}</ion-button
        >
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { fileToBase64 } from '@/modules/b2b/services/libs/image';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { chevronBackOutline, shareOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';
export default defineComponent({
  name: 'PdfPreview',
  emits: ['onDismiss'],
  props: {
    pdfUrls: {
      type: Array,
      default: () => []
    }
  },
  components: {
    VuePdfEmbed
  },
  setup(props, { emit }) {
    const onDismiss = () => {
      emit('onDismiss');
    };
    const getFileNameFromUrl = (url) => {
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1];
    };
    const getFileExtension = (url) => {
      const parts = url.split('.');
      return parts[parts.length - 1];
    };
    const isBatchDoc = ref(
      props.pdfUrls.length === 1 && getFileExtension(props.pdfUrls[0]).toLowerCase() === 'zip'
    );

    const openSocialSharing = async () => {
      const sharePromises = props.pdfUrls.map(async (url) => {
        const downloadedPdf = await fetch(url);
        const pdfBlob = await downloadedPdf.blob();
        const file64 = await fileToBase64(pdfBlob);
        const fileName = getFileNameFromUrl(url);

        await Filesystem.writeFile({
          path: fileName,
          data: file64,
          directory: Directory.Cache
        });

        const uriResult = await Filesystem.getUri({
          directory: Directory.Cache,
          path: fileName
        });
        return {
          title: url,
          text: url,
          files: [uriResult.uri]
        };
      });
      const shareResults = await Promise.all(sharePromises);
      const combinedResult = {
        title: shareResults.map((result) => result.title).join(', '),
        text: shareResults.map((result) => result.text).join(', '),
        files: shareResults.flatMap((result) => result.files)
      };
      return Share.share(combinedResult);
    };
    const isRenderingDoc = ref(true);
    return {
      onRender() {
        isRenderingDoc.value = false;
      },
      isRenderingDoc,
      chevronBackOutline,
      shareOutline,
      openSocialSharing,
      onDismiss,
      Filesystem,
      Directory,
      isBatchDoc
    };
  }
});
</script>
